<template>
  <b-card
    no-body
    class="business-card"
  >

    <b-card-header
      v-if="parcelsList.length>0"
      class="p-1"
    >
      <b-card-title class="font-medium-2">
        <b-form-checkbox
          v-model="allSelected"
          @change="toggleAll"
        >
          {{ allSelected ? $t('Un-select All') : $t('Select All') }}
        </b-form-checkbox>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-card
        v-if="parcelsList.length==0"
        border-variant="warning"
        bg-variant="transparent"
        class="shadow-none"
      >
        <b-card-text>
          {{ $t('No parcels available.') }}
        </b-card-text>
      </b-card>
      <div class="business-items">
        <b-form-checkbox-group
          id="checkbox-group"
          v-model="selected"
          name="flavour"
        >
          <div
            v-show="false"
            class="business-item"
          >
            <b-row>
              <b-col>{{ $t('Title') }}</b-col>
              <b-col>{{ $t('Courier') }}</b-col>
              <b-col>{{ $t('Weight') }}</b-col>
              <b-col>{{ $t('Volume') }}</b-col>
              <b-col>{{ $t('Value') }}</b-col>
            </b-row>
          </div>
          <div
            v-for="Item in parcelsList"
            :key="Item.id"
            class="business-item"
          >
            <b-row>
              <b-col>
                <b-form-checkbox
                  :value="Item.id"
                  checked="false"
                  :disabled="Item.receive_time == null"
                  @change="changeCheck"
                >
                  {{ Item.title }}
                </b-form-checkbox>
              </b-col>
              <b-col>
                <b-img
                  v-if="Item.freight_type == 'S'"
                  :src="require('@/assets/images/ship2u/sea-freight.png')"
                  width="64"
                />
                <b-img
                  v-else-if="Item.freight_type == 'F'"
                  :src="require('@/assets/images/ship2u/air-freight.png')"
                  width="40"
                />
              </b-col>
              <b-col>
                {{ Item.local_courier_name }} {{ Item.local_courier_number }}
              </b-col>
              <b-col>
                <span v-if="Item.receive_time == null">{{ $t('Not Received') }}</span>
                <span v-else>{{ $DateFormat(Item.receive_time) }}</span>
              </b-col>
              <b-col>
                {{ Item.weight.toFixed(2) }} KG | {{ Item.volume.toFixed(3) }} m3 | {{ Item.pieces }} pcs
              </b-col>
              <b-col>
                <b-badge :variant="Item.receive_time == null ? '' : 'success'">
                  {{ Item.parcel_status.title }}
                </b-badge>
              </b-col>
              <b-col>
                <b-badge>$ {{ Item.total }}</b-badge>
              </b-col>
            </b-row>
          </div>
        </b-form-checkbox-group>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormCheckbox, BBadge, BRow, BCol, BFormCheckboxGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BRow,
    BFormCheckboxGroup,
    BCol,
    BBadge,
    BCardBody,
    BFormCheckbox,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allSelected: false,
      selected: [],
    }
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.allParcelIds : []
      this.changeCheck()
    },
  },
  setup() {
    const parcelsList = ref([])
    const allParcelIds = ref([])
    function changeCheck() {
      // console.log(e.toString())
      // console.log(this.selected.toString())
      this.$emit('changeOrderParcels', this.selected)
    }
    store
      .dispatch('ship2u/refreshParcels', {
        state: 'P',
        filed: 1,
        unsent: 1,
        count: 0,
      })
      .then(parcels => {
        console.log(parcels)
        parcelsList.value = parcels
        parcels.forEach(i => {
          allParcelIds.value.push(i.id)
        })
        // console.log(allParcelIds.value)
      })
    return {
      parcelsList,
      changeCheck,
      allParcelIds,
    }
  },
}
</script>
